// © Microsoft Corporation. All rights reserved.
import { AudioDeviceInfo, VideoDeviceInfo, RemoteVideoStream } from '@azure/communication-calling';
import {
  CommunicationIdentifierKind
} from '@azure/communication-common';
import { CommunicationUserToken } from '@azure/communication-identity';
import preval from 'preval.macro';

const preUrl = document.referrer;
let apiUrl = '';
if (preUrl.indexOf('https://www.swallow.pro') > -1){
  apiUrl = 'https://www.swallow.pro';
} else if (preUrl.indexOf('https://admin.swallow.pro') > -1){
  apiUrl = 'https://admin.swallow.pro';
} else if (preUrl.indexOf('https://dev.swallow.pro') > -1){
  apiUrl = 'https://dev.swallow.pro';
} else if (preUrl.indexOf('https://devadmin.swallow.pro') > -1){
  apiUrl = 'https://devadmin.swallow.pro';
} else if (preUrl.indexOf('http://20.194.31.39:8000') > -1){
  apiUrl = 'http://20.194.31.39:8000';
} else if (preUrl.indexOf('http://20.194.31.39:8800') > -1){
  apiUrl = 'http://20.194.31.39:8800';
} else if (preUrl.indexOf('https://smapadmin.swallow.pro') > -1) {
  apiUrl = 'https://smapadmin.swallow.pro';
} else if (preUrl.indexOf('https://smap.swallow.pro') > -1) {
  apiUrl = 'https://smap.swallow.pro';
} else {
  apiUrl = 'https://smap.swallow.pro';
}

if (! apiUrl){
  alert('11잘못된 접근입니다.')
}

const getParameterByName = (name:String, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return '';
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const getUserToken = () => {
  const userToken = document.cookie.match('(^|;) ?token=([^;]*)(;|$)')
  let retVal = '';
  if (userToken) {
    retVal = userToken[2]
  } else {
    retVal = getParameterByName("userToken");
  }
  console.log("getUserToken() : ", retVal);
    return retVal;
}

export const getModerator = () => {
  const moderator = document.cookie.match('(^|;) ?moderator=([^;]*)(;|$)')
  let retVal = '';
  if (moderator) {
    retVal = moderator[2]
  } else {
    retVal = getParameterByName("moderator");
  }
    return retVal;
}

export const utils = {
  getAppServiceUrl: (): string => {
    return window.location.origin;
  },
  getTokenForUser: async (): Promise<CommunicationUserToken> => {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Token ' + getUserToken()
    };
    const method = 'get';
    const reqOpt = {
      method,
      headers,
    };
    const response = await fetch(encodeURI(`${apiUrl}/api/v1/consultation/token`), reqOpt)
    .then(response => response.json())
    .then(jsonData => {
      console.log(jsonData)
      return jsonData;
    });
    if (response.response) return response.response;
    
    // const response = await fetch('/token');
    // if (response.ok) {
    //   return response.json();
    // }
    throw new Error('Invalid token response');
  },
  getRefreshedTokenForUser: async (identity: string): Promise<string> => {

    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Token ' + getUserToken()
    };
    const method = 'get';
    const reqOpt = {
      method,
      headers,
    };
    const response = await fetch(encodeURI(`${apiUrl}/api/v1/consultation/token/refresh/${identity}`), reqOpt)
    .then(response => response.json())
    .then(jsonData => {
      console.log(jsonData)
      return jsonData;
    });
    if (response.token) return response.token;

    // const response = await fetch(`/refreshToken/${identity}`)
    // if (response.ok) {
    //   let content = await response.json();
    //   return content.token;
    // }
    throw new Error('Invalid token response');
  },
  isSelectedAudioDeviceInList(selected: AudioDeviceInfo, list: AudioDeviceInfo[]): boolean {
    return list.filter((item) => item.name === selected.name).length > 0;
  },
  isSelectedVideoDeviceInList(selected: VideoDeviceInfo, list: VideoDeviceInfo[]): boolean {
    return list.filter((item) => item.name === selected.name).length > 0;
  },
  isMobileSession(): boolean {
    return window.navigator.userAgent.match(/(iPad|iPhone|iPod|Android|webOS|BlackBerry|Windows Phone)/g)
      ? true
      : false;
  },
  isSmallScreen(): boolean {
    return window.innerWidth < 700 || window.innerHeight < 400;
  },
  isUnsupportedBrowser(): boolean {
    return window.navigator.userAgent.match(/(Firefox)/g) ? true : false;
  },
  getId: (identifier: CommunicationIdentifierKind): string => {
    switch(identifier.kind) {
      case 'communicationUser':
        return identifier.communicationUserId;
      case 'phoneNumber':
        return identifier.phoneNumber;
      case 'microsoftTeamsUser':
        return identifier.microsoftTeamsUserId;
      case 'unknown':
        return identifier.id;
    }
  },
  getStreamId: (userId: string, stream: RemoteVideoStream): string => {
    return `${userId}-${stream.id}-${stream.mediaStreamType}`;
  },
  /*
   * TODO:
   *  Remove this method once the SDK improves error handling for unsupported browser.
   */
  isOnIphoneAndNotSafari(): boolean {
    const userAgent = navigator.userAgent;
    // Chrome uses 'CriOS' in user agent string and Firefox uses 'FxiOS' in user agent string.
    if (userAgent.includes('iPhone') && (userAgent.includes('FxiOS') || userAgent.includes('CriOS'))) {
      return true;
    }
    return false;
  },
  getBuildTime: (): string => {
    const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;
    return dateTimeStamp;
  }
};
