import { loadTheme, initializeIcons } from '@fluentui/react';
import React, { useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';

import ChatScreen from './containers/ChatScreen';
import ConfigurationScreen from './containers/ConfigurationScreen';
import EndScreen from './components/EndScreen';
import RemovedFromThreadScreen from './components/RemovedFromThreadScreen';
import HomeScreen from './containers/HomeScreen';
// import { reducer } from './core/reducers/index';
import { getBuildTime, getChatSDKVersion, getThreadId } from './utils/utils';
// import { addUserToThread, isValidThread } from './core/sideEffects';

console.info(`Azure Communication Services chat sample using @azure/communication-chat : ${getChatSDKVersion()}`);
console.info(`Build Date : ${getBuildTime()}`);

loadTheme({});
initializeIcons();

// const store = createStore(reducer, applyMiddleware(thunk));

export default (): JSX.Element => {
  const [page, setPage] = useState('home');
  // const [validThread, setValidThread] = useState<boolean | undefined>(undefined);

  // const state = useSelector((store) => store);
  // const dispatch = useDispatch();

  const getComponent = () => {

    // if (validThread === undefined) {
    //   return 'loading...'
    // }

    // if (validThread === true) {
    //   return (
    //     <ChatScreen
    //       removedFromThreadHandler={() => setPage('removedFromThread')}
    //       leaveChatHandler={() => setPage('end')}
    //     />
    //   );
    // }
  
    // if (validThread === false) {
    //   return (
    //     <div>
    //       <p>thread Id is not valid</p>
    //     </div>
    //   );
    // }

    if (page === 'home') {
      return <HomeScreen />;
    } else if (page === 'configuration') {
      return <ConfigurationScreen joinChatHandler={() => setPage('chat')} />;
    } else if (page === 'chat') {
      return (
        <ChatScreen
          removedFromThreadHandler={() => setPage('removedFromThread')}
          leaveChatHandler={() => setPage('end')}
        />
      );
    } else if (page === 'end') {
      return (
        <EndScreen
          rejoinHandler={() => {
            window.location.href = window.location.href;
          }}
          homeHandler={() => (window.location.href = window.location.origin)}
        />
      );
    } else if (page === 'removedFromThread') {
      return <RemovedFromThreadScreen homeHandler={() => (window.location.href = window.location.origin)} />;
    }
    return null;
  };

  if (getThreadId() && page === 'home') {
    setPage('configuration');
  }
  

  // useEffect(() => {
  //   const displayName = state.calls.callAgent.displayName;
  //   console.log('displayName:', displayName);
  //   console.log('displayName:', displayName);
  //   console.log('displayName:', displayName);
  //   console.log('displayName:', displayName);
  //   const isValidThreadCheck = async () => {
  //     const threadId = getThreadId();
  //     console.log('threadId:', threadId);
  //     if (await isValidThread(threadId)) {
  //       await setValidThread(true);
  //       await dispatch(addUserToThread(displayName, CAT));
  //     } else {
  //       setValidThread(false);
  //     }
  //   };
  //   isValidThreadCheck();

  // }, []);

  return <>{getComponent()}</>;
};
