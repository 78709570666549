import { RemoteParticipant, RemoteVideoStream } from '@azure/communication-calling';
import { combineReducers, Reducer } from 'redux';
import { devicesReducer, DevicesState } from '../reducers/devices';
import { streamsReducer, StreamsState } from './streams';
import { controlsReducer, ControlsState } from './controls';
import { SdkState, sdkReducer } from './sdk';
import { CallsState, callsReducer} from './calls';
// import { CallTypes } from '../actions/calls';
// import { ControlTypes} from '../actions/controls';
// import { DeviceTypes} from '../actions/devices';
// import { SdkTypes} from '../actions/sdk';
// import { StreamTypes} from '../actions/streams';

// Chat
// import { ContosoActionTypes } from '../../chatCore/actions/ContosoClientAction';
// import { ConversationsActionTypes } from '../../chatCore/actions/ConversationsAction';
// import { MessagesActionTypes } from '../../chatCore/actions/MessagesAction';
// import { ThreadActionTypes } from '../../chatCore/actions/ThreadAction';
// import { ThreadMembersActionTypes } from '../../chatCore/actions/ThreadMembersAction';
import { ContosoReducer, ContosoState } from '../../chat/core/reducers/ContosoClientReducers';
import { ConversationsReducer, ConversationsState } from '../../chat/core/reducers/ConversationsReducers';
import { MessagesReducer, MessagesState } from '../../chat/core/reducers/MessagesReducer';
import { ThreadReducer, ThreadState } from '../../chat/core/reducers/ThreadReducers';
import { ThreadMembersReducer, ThreadMembersState } from '../../chat/core/reducers/ThreadMembersReducers';

export interface ParticipantStream {
  user: RemoteParticipant;
  stream: RemoteVideoStream | undefined;
}

export interface State {
  calls: CallsState;
  devices: DevicesState;
  streams: StreamsState;
  controls: ControlsState;
  sdk: SdkState;
  chat: MessagesState;
  contosoClient: ContosoState;
  conversations: ConversationsState;
  thread: ThreadState;
  threadMembers: ThreadMembersState;
}

type AppTypes = any;
// type AppTypes = CallTypes | ControlTypes | DeviceTypes | SdkTypes | StreamTypes 

export const reducer: Reducer<State, AppTypes> = combineReducers({
  calls: callsReducer,
  devices: devicesReducer,
  streams: streamsReducer,
  controls: controlsReducer,
  sdk: sdkReducer,
  chat: MessagesReducer,
  contosoClient: ContosoReducer,
  conversations: ConversationsReducer,
  thread: ThreadReducer,
  threadMembers: ThreadMembersReducer
});
