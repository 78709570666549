// © Microsoft Corporation. All rights reserved.
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider, teamsTheme, mergeThemes } from '@fluentui/react-northstar';
import { svgIconStyles } from '@fluentui/react-northstar/dist/es/themes/teams/components/SvgIcon/svgIconStyles';
import { svgIconVariables } from '@fluentui/react-northstar/dist/es/themes/teams/components/SvgIcon/svgIconVariables';
import * as siteVariables from '@fluentui/react-northstar/dist/es/themes/teams/siteVariables';
// import { Image, ImageFit } from '@fluentui/react';
import logoPNG from './assets/logo.png';

const iconTheme = {
  componentStyles: {
    SvgIcon: svgIconStyles
  },
  componentVariables: {
    SvgIcon: svgIconVariables
  },
  siteVariables
};

// const imageProps = {
//   src: logoSVG.toString(),
//   imageFit: ImageFit.cover,
//   maximizeFrame: true
// };


ReactDOM.render(
  <Provider theme={mergeThemes(iconTheme, teamsTheme)} className="wrapper">
    <div className="logo-wrapper">
      <img id ="logo" src={logoPNG}></img>
    </div>
    <App />
  </Provider>,
  document.getElementById('root')
);
