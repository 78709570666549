import { Reducer } from 'redux';
import { LocalVideoStream } from '@azure/communication-calling';
import { ParticipantStream } from './index';
import {
  SET_LOCAL_VIDEO_STREAM,
  ADD_SCREENSHARE_STREAM,
  StreamTypes,
  REMOVE_SCREENSHARE_STREAM
} from '../actions/streams';
import { DeviceTypes, SET_VIDEO_DEVICE_INFO } from '../actions/devices';

export interface StreamsState {
  screenShareStreams: ParticipantStream[];
  localVideoRendererIsBusy: boolean;
  localVideoStream?: LocalVideoStream;
}

const initialState: StreamsState = {
  localVideoRendererIsBusy: false,
  localVideoStream: undefined,
  screenShareStreams: []
};

const getParameterByName = (name:String, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return '';
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const isVideoCookie = document.cookie.match('(^|;) ?isVideo=([^;]*)(;|$)');
let isVideo = false
if (isVideoCookie){
  isVideo = isVideoCookie[2] === 'True' ? true : false;
} else {
  let is_video = getParameterByName('isVideo')
  if (is_video === 'true') {
    isVideo = true
  }
}

export const streamsReducer: Reducer<StreamsState, StreamTypes | DeviceTypes> = (
  state = initialState,
  action: StreamTypes | DeviceTypes
): StreamsState => {
  switch (action.type) {
    case SET_VIDEO_DEVICE_INFO:
      // if (state.localVideoStream && action.videoDeviceInfo) {
      if (action.videoDeviceInfo && isVideo) {
        return { ...state, localVideoStream: new LocalVideoStream(action.videoDeviceInfo) };
      }
      return state;
    case SET_LOCAL_VIDEO_STREAM:
      return { ...state, localVideoStream: action.localVideoStream };
    case ADD_SCREENSHARE_STREAM:
      const newScreenShareStream: ParticipantStream = { stream: action.stream, user: action.user };
      return { ...state, screenShareStreams: [...state.screenShareStreams, newScreenShareStream] };
    case REMOVE_SCREENSHARE_STREAM:
      const screenShareStreams = state.screenShareStreams.filter(
        (stream) => stream.stream !== action.stream && stream.user !== action.user
      );
      return { ...state, screenShareStreams };
    default:
      return state;
  }
};
